import {GetCompaniesAssociatedWithTheAccount} from "../state/fetch/user/CompaniesAssociatedWithTheAccount";
import {Sidebar} from "./sidebar";
import {Route, Switch, Link, useLocation} from "react-router-dom";
import {NewLeads} from "./leads/NewLeads";
import {getURL} from "../utils/postForm";
import React, {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../state/applicationState";
import {IntResp} from "../types/models";
import {cache, mutate} from "swr"
import {Company} from "./company";
import {Stats} from "./stats";
import {Filter} from "./sidebar/Filter";
import {CreateLead} from "./createLead";
import {Container} from "./container";
import {TrackedCallsUpload} from "./upload/TrackedCallsUpload";
import {UploadJobs} from "./upload/UploadJobs";
import {Jobs} from "./jobs";
import {AttachedJobs} from "./attached_jobs";


export const Application = () => {
    const [loading, setLoading] = useState(false);
    const [newNumber, setNewNumber] = useState(0)

    const {currentCompany, param, setParam, globalError} = useContext(ApplicationContext)

    // Takes in the path and sets the param of the query
    let location = useLocation();
    useEffect(() => {
        if (location.pathname.includes("new-leads")) {
            setParam("new=true")
        } else if (location.pathname.includes("snoozed")) {
            setParam("snoozed=true")
        } else if (location.pathname.includes("entered-leads")) {
            setParam("entered=true")
        } else if (location.pathname.includes("rejected-leads")) {
            setParam("removed=true")
        } else if (location.pathname.includes("non-google-leads")) {
            setParam("non-google=true")
        } else if (location.pathname.includes("failed-leads")) {
            setParam("failed-leads=true")
        }
    }, [location])


    const getLeads = async () => {
        setLoading(true)
        try {
            const resp = await getURL(`/v1/api/leads/${currentCompany.UUID}/get-callrail-leads`, "")
            let read = await resp.responseMessage.json();
            if (!resp.isOk) {
                window.alert(read.message);
            }
            let marshalled = read as IntResp
            console.log("read is", read);
            setNewNumber(marshalled.NumberOfRecords)
            setLoading(false)
            cache.clear()
            mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads?${param}`)
        } catch {
            setLoading(false);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setNewNumber(0)
        }, 5000)
    }, [newNumber])

    // Account Wide Data
    GetCompaniesAssociatedWithTheAccount()

    return (
        <>
            <div className={"absolute top-4 right-4"}>
                {globalError && <p className={"text-white"}>{globalError}</p>}
            </div>
            <div className={"pl-12 bg-blueGray-300 pt-4 flex items-center"}>
                <button disabled={loading} onClick={() => getLeads()}
                        className={`border-2 border-blueGray-500 rounded-xl text-blueGray-600 font-medium text-lg px-2 py-1 flex ${loading && "cursor-wait opacity-50"}`}>
                    <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                         width="24px" fill="#333">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path
                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
                    </svg>
                    Check For Recent Leads
                </button>
                {newNumber !== 0 && (
                    <p className={"text-blueGray-600 text-xl my-auto ml-4 font-bold"}>Retrieved {newNumber} New
                        Leads</p>)}
            </div>
            <div className={"bg-blueGray-300 min-h-full flex pl-12 pt-8"}>
                <div className={"min-w-[18rem] max-w-[18rem]"}>
                    <Sidebar/>
                </div>
                <div className={"ml-12 break-words w-full max-w-4xl mr-12"}>
                    <div>
                        <Switch>
                            <Route path={"/leads/:page"}>
                                <Filter jobsDropDown={false} shouldShowDropdown={true} text={"search for name or number"}/>
                                <Container>
                                    <NewLeads/>
                                </Container>
                            </Route>
                            <Route path={"/found-jobs/:page"}>
                                <Container>
                                    <AttachedJobs/>
                                </Container>
                            </Route>
                            <Route path={"/company"}>
                                <Container>
                                    <Company/>
                                </Container>
                            </Route>
                            <Route path={"/stats"}>
                                <Container>
                                    <Stats/>
                                </Container>
                            </Route>
                            <Route path={"/create-lead"}>
                                <Container>
                                    <CreateLead/>
                                </Container>
                            </Route>
                            <Route path={"/job-upload"}>
                                <Container>
                                    <UploadJobs/>
                                </Container>
                            </Route>
                            <Route path={"/tracked-calls-upload"}>
                                <Container>
                                    <TrackedCallsUpload/>
                                </Container>
                            </Route>
                            <Route path={"/jobs/:page"}>
                                <Filter jobsDropDown={true} shouldShowDropdown={false} text={"search by ID/phone"}/>
                                <Container>
                                    <Jobs/>
                                </Container>
                            </Route>

                            <Route path={"/*"}>
                                <Container>
                                    <div>
                                        Nothing Here 🤷‍♀️
                                    </div>
                                    <Link className={"underline text-xl text-blue-800 mt-8"} to={"/leads/1"}>Go To
                                        Leads</Link>
                                </Container>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    )
}