export type responseMessage = {
    message: string
}

export const postForm = async (path: string, data: string ) => {
    const response = await fetch(`${process.env.REACT_APP_API}${path}`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Expose-Headers": "*"
},
        body: data
    })
    let responseJSON = await response.json()
    console.log("response json is", responseJSON);
    return {
        isOk: response.ok,
        responseMessage: responseJSON as responseMessage,
    }
}

export const patchForm = async (path: string, data: string ) => {
    const response = await fetch(`${process.env.REACT_APP_API}${path}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Expose-Headers": "*"
        },
        body: data
    })
    return {
        isOk: response.ok,
        responseMessage: response
    }
}

export const getURL = async (path: string, data: string ) => {
    const response = await fetch(`${process.env.REACT_APP_API}${path}`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Expose-Headers": "*"
        },
    })
    return {
        isOk: response.ok,
        responseMessage: response
    }
}

// <>
//     <div className={"flex flex-col "}>
// <label className={"pb-4"}>
//     Email
//     </label>
//     <input ref={emailField} autoComplete={"email"} id={"email"} value={email}
// onInput={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
// className={"bg-blueGray-100 rounded-2xl px-4 py-2"}/>
// </div>
// <div className={"flex flex-col text-xl mt-8"}>
// <label className={"pb-4"}>
//     Password
//     </label>
//     <input autoComplete={"current-password"} id={"password"} value={password}
// onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
// type={"password"} className={"bg-blueGray-100 rounded-2xl px-4 py-2"}/>
// </div>
// <button onClick={(e) => {
//     e.preventDefault()
//     submit()
// }} className={"bg-indigo-500 text-white w-full rounded-2xl py-2 mt-6 "}>Login
//     </button>
//     </>