import {Lead} from "../../types/models";
import {useState} from "react";
import {Input} from "./input";
import {UseCreateNewLead} from "../../state/fetch/newLead/createNewLead";


export const CreateLead = () => {

    const [lead, setLead] = useState({
        customer_name: "",
        Gclid: "",
        customer_phone_number: "",
        start_time: "" as any,
        LeadValue: 0,
    } as Lead)

    let {create, waiting} = UseCreateNewLead({lead})

    return (
        <div className={"space-y-6"}>
            <p className={"text-2xl font-bold mb-8"}>Please Follow Input Guidelines Exactly</p>
            <p className={"text-red-900 font-bold mb-8"}>Please only enter leads 36 hours old or older to avoid bugs caused by Google's handling of time zones.</p>
            <div className={"flex flex-col text-xl "}>
                <label className={"pb-3"}>
                    GCLID (Required unless lead came through call extension)
                </label>
                <input className={" bg-blueGray-100 px-4 py-2 rounded-xl"} value={lead.Gclid} onChange={(e) => {
                    let copiedLead = {...lead}
                    copiedLead.Gclid = e.target.value;
                    setLead(copiedLead as typeof lead)
                }
                }/>
            </div>
        <div className={"flex flex-col text-xl "}>
            <label className={"pb-3"}>
                Name
            </label>
            <input className={" bg-blueGray-100 px-4 py-2 rounded-xl"} value={lead.customer_name} onChange={(e) => {
                let copiedLead = {...lead}
                copiedLead.customer_name = e.target.value;
                setLead(copiedLead as typeof lead)
            }
            }/>
        </div>
            <div className={"flex flex-col text-xl"}>
                <label className={"pb-3"}>
                    Number (Format: +15416015045)
                </label>
                <input className={" bg-blueGray-100 px-4 py-2 rounded-xl"} value={lead.customer_phone_number} onChange={(e) => {
                    let copiedLead = {...lead}
                    copiedLead.customer_phone_number = e.target.value;
                    setLead(copiedLead as typeof lead)
                }
                }/>
            </div>
            <div className={"flex flex-col text-xl"}>
                <label className={"pb-3"}>
                    Value (Format: 50.04)
                </label>
                <input type={"number"} className={" bg-blueGray-100 px-4 py-2 rounded-xl"} value={lead.LeadValue ? lead.LeadValue : ""} onChange={(e) => {
                    let copiedLead = {...lead}
                    copiedLead.LeadValue = e.target.value as unknown as number;
                    setLead(copiedLead as typeof lead)
                }
                }/>
            </div>
            <div className={"flex flex-col text-xl"}>
                <label className={"pb-3"}>
                   Date of Call (Format Is 2021/05/15) - YYYY/MM/DD
                </label>
                <input className={" bg-blueGray-100 px-4 py-2 rounded-xl"} value={lead.start_time as string} onChange={(e) => {
                    let copiedLead = {...lead}
                    copiedLead.start_time = e.target.value;
                    setLead(copiedLead as typeof lead)
                }
                }/>
            </div>
            <button onClick={() => create()} className={"text-xl bg-indigo-800 text-white px-8 py-2 rounded-xl"}>Submit</button>
        </div>
    )
}