import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../applicationState";
import useSWR from "swr";
import {fetcher} from "../../../utils/fetch";
import {CampaignTotal, ErrorMessage} from "../../../types/models";

type returnInterface = {
    Error: ErrorMessage | null;
    Data: CampaignTotal[] | null;
}

export const UseGetStats = (): returnInterface => {
    const [mounted, setMounted] = useState(false);

    const {currentCompany} = useContext(ApplicationContext);
    const {data, error} = useSWR(`${mounted ? `${process.env.REACT_APP_API}/v1/api/stats/${currentCompany.UUID}/stats` : undefined}`, fetcher);
    useEffect(() => {
        if(currentCompany && currentCompany.UUID){
            setMounted(true);
        }
    }, [currentCompany])

    if(data && currentCompany.UUID){
        return {
            Data: data as CampaignTotal[],
            Error: null
        } as returnInterface;
    }
    if (error){
        return {
            Data: null,
            Error: error as ErrorMessage
        } as returnInterface
    }
    return {
        Error: null,
        Data: null,
    }
}