import { useHistory } from 'react-router'
import {useContext, useRef, useState} from "react";
import {postForm} from '../../utils/postForm';
import {ApplicationContext} from "../../state/applicationState";


export const UploadJobs = () => {
    const {currentCompany} = useContext(ApplicationContext)
    const buttonRef = useRef(null)
    const [key, setKey] = useState("")
    const [selectedFile, setSelectedFile] = useState<undefined | File>(undefined);
    const history = useHistory()


    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!selectedFile) {
            return
        }
        console.log(selectedFile);
        const formData = new FormData();
        formData.append("name", "file");
        if (selectedFile) {
            formData.append("bulkUpload", selectedFile);
        }
        const response = await fetch(`${process.env.REACT_APP_API}/v1/api/internal-upload/${currentCompany.UUID}/upload-jobs`, {
            method: "POST",
            body: formData,
            credentials: "include",
        })
        const read = await response.json();
        console.log("read is", read)
        window.alert(read.message);
        setSelectedFile(undefined);
        history.go(0)
    }

    const addFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setSelectedFile(e.target.files[0]);
    };

    // @ts-ignore
    const handleOnError = (err, file, inputElem, reason) => {
        window.alert(err)
    }

    const handleFileLoad = async (data: any) => {
        console.log("Sending", data);
        let resp = await postForm(`/v1/api/internal-upload/${currentCompany.UUID}/upload-jobs`, JSON.stringify(data))
        console.log("response is", resp.responseMessage);
        if (!resp.isOk) {
            window.alert(resp.responseMessage.message);
        } else {
            window.alert(resp.responseMessage.message);
        }
        setKey("")
    }

    return (
        <div>
            <h1 className={"text-3xl font-bold text-blueGray-700 mb-10"}>Upload Jobs</h1>
            <div className={"text-xl space-y-4 mb-8"}>
                <p>CSV Headers (In no particular oder)</p>
                <p>Date | ID | Phone | Total</p>
                <p>Don't worry about double uploading a job, as long as the job has an ID, it will not be double counted.</p>
                <p>Important: Don't forget about Call extension uploads.</p>
            </div>
            <div className={"flex mt-20"}>
                <form onSubmit={handleSubmit} className={"flex max-w-md mx-auto flex-col text-xl"}>
                    <input accept={".csv"} onChange={(e) => addFile(e)}  type="file" name="picture" />
                    <button className={"mt-4 bg-green-800 text-white px-6 py-2 rounded-xl"}>Submit</button>
                </form>
            </div>
        </div>
    )
}