import useSWR from "swr";
import {fetcher} from "../../utils/fetch";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../state/applicationState";
import {Link, useLocation, useParams} from "react-router-dom";
import {NewJobsData} from "../../state/fetch/jobs/allJobs";
import {Job} from "../../types/models";

export const Jobs = () => {
    useEffect(() => {

    }, [])
    const {jobs} = useContext(ApplicationContext)
    // @ts-ignore
    let {page} = useParams()
    let err = NewJobsData({page})


    return (
        <div className={"relative"}>
            <div className={"flex items-end justify-end mb-2 absolute -top-8 right-0 "}>
                {page > 1 && (
                    <Link to={`/jobs/${parseInt(page) - 1}`}>👈</Link>
                )}
                <span> {page}</span>
                <Link to={`/jobs/${parseInt(page) + 1}`}>👉</Link>
            </div>
            {jobs && jobs.map((job) => {
                return (
                    <div key={job.primary_id}>
                        <SingleJob job={job}/>

                    </div>
                )
            })}
        </div>
    )
}

type props = {
    job: Job
}

const SingleJob = (props: props) => {
    const makeTime = (t: string) => {
        let time = new Date(t);
        // console.log("time is", time.getMonth() + time.getHours() + time.getMinutes());
        return time.toLocaleString()
    }
    return (
        <div
            className={"flex justify-around bg-blueGray-100 rounded-xl px-4 my-4 py-4 text-xl divide-x-4 divide-coolGray-200"}>

            <div className={"flex flex-col space-y-4 pr-4"}>
                <p>ID: {props.job.external_id}</p>
                <p>Date: {makeTime(props.job.job_last_updated ? props.job.job_last_updated.toString() : "")}</p>
                <p>Potential phone
                    matches: {props.job.potential_phone_numbers && props.job.potential_phone_numbers.map((phone) => phone + ",")}</p>
                <p>Matched To Lead: {props.job.google_ads_lead_matched_id}</p>
            </div>
            <div className={"flex flex-col space-y-4 pl-4"}>
                <p>Value: {props.job.value}</p>
                <p>Submitted Value: {props.job.submitted_value}</p>
            </div>
        </div>
    )
}