import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {Auth} from "./auth";
import {ApplicationContext, ApplicationState} from "./state/applicationState";
import {Application} from "./application";
import useSWR, {SWRConfig} from 'swr'
import {TrackedCallsUpload} from "./application/upload/TrackedCallsUpload";


function App() {
    let values = ApplicationState();
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={"/log-in"}>
                    <Auth/>
                </Route>

                <Route path={"/"}>
                    <ApplicationContext.Provider value={values}>
                        <SWRConfig
                            value={{
                                // refreshInterval: 0,
                            }}
                        >
                            <Application/>
                        </SWRConfig>
                    </ApplicationContext.Provider>
                </Route>

            </Switch>
        </BrowserRouter>
    );
}

export default App;
