import {useEffect, useRef, useState} from "react";
import {postForm} from "../utils/postForm";
import {Formik} from 'formik';
import { useHistory } from "react-router-dom";


export const Auth = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const emailField = useRef(null)
    let history = useHistory();
    const submit = async () => {
        let sendObj = {
            email: email,
            password: password,
        }
        let obj = await postForm("/v1/api/users/authenticate", JSON.stringify(sendObj))
        if (!obj.isOk) {
            setError(obj.responseMessage.message);
        } else {
            history.push("/new-leads/1")
        }
        console.log(obj);
    }


    return (
        <div className={"min-h-screen w-full flex bg-blueGray-300"}>
            <div className={"max-w-xl m-auto bg-white p-12 shadow-xl rounded-2xl text-xl"}>


                <div className={"flex flex-col "}>
                    <label className={"pb-4"}>
                        Email
                    </label>
                    <input ref={emailField} autoComplete={"email"} id={"email"} value={email}
                           onInput={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                           className={"bg-blueGray-100 rounded-2xl px-4 py-2"}/>
                </div>
                <div className={"flex flex-col text-xl mt-8"}>
                    <label className={"pb-4"}>
                        Password
                    </label>
                    <input autoComplete={"current-password"} id={"password"} value={password}
                           onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                           type={"password"} className={"bg-blueGray-100 rounded-2xl px-4 py-2"}/>
                </div>
                <button onClick={(e) => {
                    e.preventDefault()
                    submit()
                }} className={"bg-indigo-500 text-white w-full rounded-2xl py-2 mt-6 "}>Login
                </button>
                {error !== "" && (
                    <p className={"text-center text-red-600 mt-6"}>{error}</p>
                )}

            </div>

        </div>
    )
}