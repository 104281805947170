import {NewLeadsData} from "../../state/fetch/leads/NewLeads";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../state/applicationState";
import {LeadContainer} from "./leadContainer/leadContainer";
import {  useParams, Link, useLocation} from "react-router-dom";



export const NewLeads = () => {
    const {leads, filter} = useContext(ApplicationContext)
    // @ts-ignore
    let {page} = useParams()
    let location = useLocation();

    // get the leads
    NewLeadsData({page})
    return (
        <div className={"relative"}>
            <div className={"flex items-end justify-end mb-2 absolute -top-8 right-0 "}>
                {page > 1 && (
                    <Link to={`/leads/${parseInt(page)-1}`}>👈</Link>
                )}
            <span> {page}</span>
            <Link to={`/leads/${parseInt(page)+1}`}>👉</Link>
            </div>
            <div className={"space-y-6"}>
                {leads && leads.length > 0 && leads.sort((a, b) => {
                    return new Date(b.start_time as string).getTime() - new Date(a.start_time as string).getTime()
                }).map((lead) => {
                    return (
                        <LeadContainer param={filter} key={lead.primary_id} lead={lead}/>
                    )
                })}
            </div>
        </div>
    )
}