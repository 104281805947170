import {UseGetCompanyState} from "../../state/fetch/company/CompanyState";
import {ApplicationContext} from "../../state/applicationState";
import {useContext, useEffect} from "react";
import { patchForm } from "../../utils/postForm";
import {mutate} from "swr";

export const Company = () => {
    const {companyNotes, setCompanyNotes,currentCompany} = useContext(ApplicationContext)


    const {PatchCompanyNotes} = UseGetCompanyState();

    return (
        <div className={"flex flex-col text-xl"}>
            <label className={" text-blueGray-800 mb-6"}>
                Last Date This Was Imported
            </label>
            <input className={" bg-blueGray-200 px-4 py-2 rounded-xl"} type={"text"} onChange={(e) => {
                let copy = companyNotes;
                console.log("new value is", e.target.value);
                copy.GoogleAdsPhoneReportLastUpdated = e.target.value;
                console.log("copy is", copy);
                setCompanyNotes({...copy});
            }
            } value={companyNotes.GoogleAdsPhoneReportLastUpdated}/>
            <button onClick={() => PatchCompanyNotes()} className={"mr-auto px-4 py-2 mt-4 bg-indigo-800 text-white rounded-xl"}>
                Update
            </button>
            <hr className={"mt-6"}/>
            <label className={" text-blueGray-800 mb-6 mt-12"}>
               Notes
            </label>
            <textarea className={" bg-blueGray-200 px-4 py-2 rounded-xl h-48"} cols={6}  onChange={(e) => {
                let copy = companyNotes;
                copy.notes = e.target.value;
                setCompanyNotes({...copy});
            }
            } value={companyNotes.notes}/>
            <button onClick={() => PatchCompanyNotes()} className={"mr-auto px-4 py-2 mt-4 bg-indigo-800 text-white rounded-xl"}>
                Update
            </button>
        </div>
    )
}