import {UseGetStats} from "../../state/fetch/stats/useGetStats";
import {useEffect} from "react";

export const Stats = () => {
    const {Data, Error} = UseGetStats();
    useEffect(() => {
        console.log("data is", Data);
    }, [Data])
    if (!Data){
        return (
            <div>
                Loading...
            </div>
        )
    }
    return(
        <div>
            {Error && <p className={"text-red-500 text-xl"}>{Error.message}</p>}
            {Data.map((leadInfo) => {
                return (
                    <div className={"bg-blueGray-100 rounded-xl mb-4 px-8 py-4"}>
                        <p className={"text-blueGray-700 font-bold text-2xl mb-4"}>{leadInfo.Campaign} {leadInfo.utm_campaign}</p>
                        <p className={"text-green-700 font-bold text-2xl"}>${leadInfo.Total}</p>
                    </div>
                )
            })}
        </div>
    )
}