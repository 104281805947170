export const fetcher = async (
    input: RequestInfo,
    init: RequestInit,
    ...args: any[]
) => {
    const res = await fetch(input,{
        method: "GET",
        credentials: "include",
    });
    if (res.status === 401){
        console.log("")
        window.location.href = "/log-in"
    }
    const finalRes = await res.json();
    return finalRes;
};