import {Lead} from "../../../types/models";
import {postForm} from "../../../utils/postForm";
import {useContext, useState} from "react";
import {ApplicationContext} from "../../applicationState";

type props = {
    lead: Lead
}

export const UseCreateNewLead = (props: props) => {
    const {currentCompany} = useContext(ApplicationContext)
    const [waiting, setWaiting] = useState(false)
    const create = async () => {
        setWaiting(true)
        console.log("Sending", props.lead);
        let safeLead = props.lead;
        if(safeLead.LeadValue){
            safeLead.LeadValue = parseInt(String(safeLead.LeadValue));
            console.log("Changed!")
        }
        let resp = await postForm(`/v1/api/leads/${currentCompany.UUID}/new`, JSON.stringify(safeLead))
        if (!resp.isOk){
            console.log(resp);
            window.alert(resp.responseMessage.message)
            console.log(resp.responseMessage.message)
        } else {
            window.alert("success!")
        }
        setWaiting(false);
    }
    return {create, waiting}
}