import {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {ApplicationContext} from "../../state/applicationState";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

type props = {
    text: string,
    shouldShowDropdown: boolean
    jobsDropDown: boolean
}

export const Filter = (props: props) => {
    const {filter, setFilter} = useContext(ApplicationContext)
    const [search, setSearch] = useState("")
    const {searchNumbers, setSearchNumbers} = useContext(ApplicationContext)
    const query = useQuery()
    const history = useHistory();

    // Get params from the URL and add them to the state for reload-handling
    useEffect(() => {
        const value = query.get("filter");
        if (value) {
            setFilter(value);
        }
    }, [])

    // onchange with the filter, update the URL
    useEffect(() => {
        if (filter) {
            setSearch("")
        }
        const params = new URLSearchParams()
        console.log("Filter is", filter);
        if (filter) {
            params.append("filter", filter)
        } else {
            params.delete("filter")
        }
        // Quick hack because shouldShowDropdown only works on the leads object
        if (props.shouldShowDropdown) {
            history.push("/leads/1")
            history.push({search: params.toString()})
        }
        if (props.jobsDropDown) {
            history.push("/jobs/1")
            history.push({search: params.toString()})
        }
    }, [filter])

    return (
        <div className={"bg-white shadow-xl rounded-xl px-4 py-2 mb-8 flex"}>

            <select value={filter} onChange={(e) => setFilter(e.target.value)}
                    className={`bg-blueGray-800 cursor-pointer text-xl text-blueGray-100 px-4 py-2 rounded-xl ${searchNumbers !== "" && "bg-blueGray-600 text-blueGray-200 cursor-wait"}`}>
                {props.shouldShowDropdown && (
                    <>
                        <option value={"new"} className={"rounded-xl"}>New Leads</option>
                        <option value={"has-value"} className={"rounded-xl"}>Leads With Value</option>
                        <option value={"snoozed"} className={"rounded-xl"}>Snoozed Leads</option>
                        <option value={"removed"} className={"rounded-xl"}>Removed Leads</option>
                        <option value={"non-google-ads"} className={"rounded-xl"}>Non Google Ads</option>
                        <option value={"failed"} className={"rounded-xl"}>Failed Leads</option>
                    </>
                )}
                {props.jobsDropDown && (
                    <>
                        <option value={""} className={"rounded-xl"}>All Jobs</option>
                        <option value={"attached"} className={"rounded-xl"}>Attached Leads</option>
                    </>
                )}

            </select>


            <input placeholder={props.text} value={searchNumbers} onChange={(e) => setSearchNumbers(e.target.value)}
                   type={"search"}
                   className={"ml-8 focus:border-blue-500 border-2 flex-1 bg-blueGray-800 outline-none focus:ring text-xl rounded-xl text-blueGray-100 px-4 py-2"}/>
        </div>
    )
}