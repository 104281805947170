import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../state/applicationState";
import {NavLink} from "react-router-dom";

export const Sidebar = () => {
    const {currentCompany, allCompanies, setCurrentCompany} = useContext(ApplicationContext)
    const [showCompanies, setShowCompanies] = useState(false);

    return (
        <div className={"shadow-2xl bg-white flex flex-col rounded-2xl py-8 px-8 fixed"}>
            <NavLink to={"/company"} activeClassName={"text-indigo-800"}
                     className={"text-center text-3xl cursor-pointer text-blueGray-800 font-bold"}>{currentCompany && currentCompany.CompanyName}</NavLink>
            {allCompanies.length > 1 && (<button onClick={() => setShowCompanies(!showCompanies)}
                                                 className={"bg-white mt-8 text-xl py-2 border-2 border-indigo-400 rounded-2xl flex items-center justify-center"}>
                    All Companies
                    <svg className={"ml-4"} width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 7.33L2.829 4.5L12.004 13.839L21.171 4.5L24 7.33L12.004 19.5L0 7.33Z"
                              fill="#818CF8"/>
                    </svg>
                </button>
            )}
            {showCompanies && allCompanies.map((company) => {
                return (
                    <div className={"mt-2"} key={company.UUID}>
                        <button onClick={() => {
                            setCurrentCompany(company);
                        }} className={"text-indigo-800 underline text-xl"}>{company.CompanyName}</button>
                    </div>
                )
            })}
            <div className={"text-blueGray-800 text-xl  mt-4"}>
                <div className={"flex flex-col space-y-4"}>
                    <NavLink
                        to={"/leads/1"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                        isActive={(match, location) =>{
                            if(match){
                                return true
                            }
                            if (location.pathname.includes("/leads/")){
                                return true
                            }
                            return false
                        }}
                    >
                        Leads
                    </NavLink>
                    <NavLink
                        to={"/found-jobs/1"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                        isActive={(match, location) =>{
                            if(match){
                                return true
                            }
                            if (location.pathname.includes("/found-jobs/")){
                                return true
                            }
                            return false
                        }}
                    >
                        Attributed Jobs
                    </NavLink>
                    <NavLink
                        to={"/create-lead"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                    >
                        Create Lead
                    </NavLink>
                    <NavLink
                        to={"/job-upload"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                    >
                        Job Upload
                    </NavLink>
                    <NavLink
                        to={"/jobs/1"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                    >
                        All Jobs
                    </NavLink>
                    <NavLink
                        to={"/stats"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                    >
                        📈 Stats
                    </NavLink>
                    <NavLink
                        to={"/tracked-calls-upload"}
                        className={" w-full text-left font-medium px-4 py-2 rounded-2xl mt-6"}
                        activeClassName={"bg-indigo-300 text-indigo-800"}
                    >
                        Call Extension Upload
                    </NavLink>
                </div>

            </div>
        </div>
    )
}