import {Lead, UpdateLeadInput} from "../../../types/models";
import {useContext, useEffect, useState} from "react";
import {patchForm} from "../../../utils/postForm";
import App from "../../../App";
import {ApplicationContext} from "../../../state/applicationState";
import useSWR, {mutate} from 'swr'
import {useParams} from "react-router-dom";


type props = {
    lead: Lead
    param: string
}

export const LeadContainer = (props: props) => {
    const {currentCompany, searchNumbers} = useContext(ApplicationContext)
    const [shouldAssign, setShouldAssign] = useState(false);
    const [success, setSuccess] = useState(false);
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false);
    // @ts-ignore
    let {page} = useParams()

    const makeTime = (t: string) => {
        let time = new Date(t);
        // console.log("time is", time.getMonth() + time.getHours() + time.getMinutes());
        return time.toLocaleString()
    }

    const update = async (v: UpdateLeadInput) => {
        console.log("params are", props);
        setLoading(true)
        let obj = await patchForm(`/v1/api/leads/${currentCompany.UUID}/update-lead`, JSON.stringify(v));
        let response = await obj.responseMessage.json();
        if (!obj.isOk) {
            console.log("Response is", response)
            window.alert(response.message);
        }
        // update either general URL or the search query
        mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads?${props.param}`)
        mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads/${page}?filter=${props.param}`)
        mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/search?key=${searchNumbers}`)
        console.log("Clearing", `${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads/${page}?filter=${props.param}`)
        setLoading(false);
        // https://upload-conversions-api.easylanding.io/v1/api/leads/404a2dd9-5753-4d86-9c85-bc41123b19b2/new-leads/1?has-value
        // https://upload-conversions-api.easylanding.io/v1/api/leads/404a2dd9-5753-4d86-9c85-bc41123b19b2/new-leads/1?filter=has-value

    }

    const updateValue = async (id: string, value: string) => {
        console.log("params are", props);
        setLoading(true)
        let obj = await patchForm(`/v1/api/leads/${currentCompany.UUID}/update-lead-value?new-value=${value}&lead-id=${id}`, "");
        let response = await obj.responseMessage.json();
        if (!obj.isOk) {
            console.log("Response is", response)
            window.alert(response.message);
        }
        console.log("response is", response);
        mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads?${props.param}`)
        mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads/${page}?filter=${props.param}`)
        mutate(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/search?key=${searchNumbers}`)
        console.log("Clearing", `${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads/${page}?filter=${props.param}`)
        // https://upload-conversions-api.easylanding.io/v1/api/leads/404a2dd9-5753-4d86-9c85-bc41123b19b2/new-leads/1?&filter=has-value
        // https://upload-conversions-api.easylanding.io/v1/api/leads/404a2dd9-5753-4d86-9c85-bc41123b19b2/new-leads/1?
        setLoading(false);
    }

    useEffect(() => {
        if (value !== "") {
            setShouldAssign(true);
        } else {
            setShouldAssign(false);
        }
    }, [value])

    useEffect(() => {
        if (props.lead.LeadValue) {
            setValue(props.lead.LeadValue.toString())
            setShouldAssign(false);
        }
    }, [])

    return (

        <div className={"flex flex-col bg-coolGray-100 rounded-xl"}>
            {props.lead.GoogleFailureMessage && props.lead.GoogleFailureMessage.length > 2 &&
            <p className={"text-xl px-8 pt-8 text-red-500"}>error: {props.lead.GoogleFailureMessage}</p>}
            <div
                className={` justify-around divide-x-4 divide-coolGray-200 px-8 py-10 flex text-lg text-blueGray-800 ${loading && "opacity-60"}`}
                key={props.lead.id}>
                <div className={"flex flex-1 flex-col space-y-2"}>
                    <p className={"text-2xl font-bold text-blueGray-600 mb-2"}>Lead Info</p>
                    {props.lead.start_time && <p className={"flex"}>
                        <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                             width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
                        </svg>
                        {makeTime(props.lead.start_time as string)}</p>}
                    {props.lead.customer_phone_number && <p className={"flex"}>
                        <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                             width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/>
                        </svg>
                        {props.lead.customer_phone_number}</p>}
                    {props.lead.customer_phone_number && <p className={"flex"}>
                        <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                             width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z"/>
                        </svg>
                        {props.lead.customer_name}</p>}
                    {props.lead.source && <p>
                        {props.lead.source}
                    </p>}
                    {props.lead.recording_player &&
                    <a target={"_blank"} className={"underline"} href={props.lead.recording_player}>Recording
                        ({props.lead.recording_duration}s)</a>}
                </div>
                <div className={"flex flex-1 justify-center px-6 flex-col"}>
                    <div className={"flex flex-col max-w-[12rem] mx-auto"}>
                        <label className={"font-bold mb-3"}>Lead Value</label>
                        <input value={value} type={"number"} onChange={(e) => setValue(e.target.value)}
                               className={"py-2 px-2 rounded-xl"}
                               placeholder={"$100"}/>
                        <button
                            onClick={() => updateValue(
                                props.lead.primary_id.toString(), value.toString(),
                            )}
                            className={`px-4 py-1 rounded-2xl mt-4 font-medium border-2 transition-colors  ${shouldAssign ? "bg-teal-500 text-white" : "border-teal-500 text-blueGray-800"}`}>{success ? "Success" : "Assign Value"}
                        </button>
                    </div>
                </div>
                <div className={"flex flex-1 flex-col justify-center space-y-3 pl-4 "}>
                    {!props.lead.Removed && (
                        <button
                            onClick={() => update({
                                ID: props.lead.primary_id,
                                NotGoogleAds: props.lead.NotGoogleAds ? false : true,
                            })}
                            className={"py-1 rounded-2xl bg-white px-2 transition-colors hover:bg-blueGray-800 hover:text-white "}>{props.lead.NotGoogleAds ? "Is Google Ads" : "Not Google Ads"}
                        </button>
                    )}
                    {!props.lead.NotGoogleAds && !props.lead.Removed && value === "" && (
                        <button
                            onClick={() => update({
                                ID: props.lead.primary_id,
                                Snoozed: props.lead.Snoozed ? false : true,
                            })}
                            className={"py-1 rounded-2xl bg-white px-2 transition-colors hover:bg-yellow-500 hover:text-white text-yellow-500 "}>{props.lead.Snoozed ? "Un-Snooze" : "Snooze Lead"}</button>
                    )}
                    <button
                        onClick={() => update({
                            ID: props.lead.primary_id,
                            Removed: props.lead.Removed ? false : true,
                        })}
                        className={"py-1  rounded-2xl bg-white px-2 transition-colors text-red-500 hover:bg-red-500 hover:text-white border-white"}>{props.lead.Removed ? "Add Lead Back" : "Remove Lead"}
                    </button>
                </div>

            </div>
            <div className={""}>
                <div className={"h-0 -mt-8"}>
                    {props.lead.SuccessfullySubmittedGoogleAds &&
                    <p className={"text-center pb-2 text-green-600"}>Successfully submitted to Google Ads</p>}
                </div>
            </div>

        </div>


    )
}