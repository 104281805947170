import {ApplicationContext} from "../../applicationState";
import {useContext, useEffect} from "react";
import useSWR from "swr";
import {fetcher} from "../../../utils/fetch";
import {Company} from "../../../types/models";

export const GetCompaniesAssociatedWithTheAccount = () => {
    const {setAllCompanies, setCurrentCompany, allCompanies, currentCompany} = useContext(ApplicationContext);
    const {data, error} = useSWR(`${process.env.REACT_APP_API}/v1/api/users/a/associated-companies`, fetcher);
    useEffect(() => {
        if (!data){
            return;
        }
        let marshalled = data as Company[];
        setAllCompanies(marshalled);
    }, [data, error])

    useEffect(() => {
        if(currentCompany && currentCompany.UUID){
            window.localStorage.setItem("currentCompanyUUID", currentCompany.UUID)
            console.log("setting company uuid as", currentCompany.UUID)
        }
    }, [currentCompany])

    useEffect(() => {
        console.log("Checking all companies")
        if (allCompanies.length === 1){
            setCurrentCompany(allCompanies[0])
        } else {
            if(window.localStorage.getItem("currentCompanyUUID")){
                allCompanies.map((company) => {
                    if (company.UUID === window.localStorage.getItem("currentCompanyUUID")){
                        console.log("found uuid")
                        setCurrentCompany(company)
                    }
                })
            } else {
                console.log("default set all companies")
                setCurrentCompany(allCompanies[0])
            }
        }
    }, [allCompanies])
    if (error){
        return {error}
    }
}