import useSWR from "swr";
import {fetcher} from "../../utils/fetch";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../state/applicationState";
import {Link, useLocation, useParams} from "react-router-dom";
import {NewJobsData} from "../../state/fetch/jobs/allJobs";
import {Job, MatchedJob} from "../../types/models";
import {MatchedJobs} from "../../state/fetch/matchedJobs/matchedJobs";

export const AttachedJobs = () => {
    const {matchedJobs, currentCompany} = useContext(ApplicationContext)
    // @ts-ignore
    let {page} = useParams()
    let err = MatchedJobs({page})

    useEffect(() => {
        console.log("matchedjobs", matchedJobs)
    }, [matchedJobs])



    return (
        <div className={"relative"}>
            <div className={"flex"}>

                <a target={"_self"} download href={`${process.env.REACT_APP_API}/v1/api/jobs/${currentCompany.UUID}/matched-jobs.csv`} className={"text-xl bg-indigo-800 text-white px-8 py-2 rounded-xl mb-4 mx-auto"}>Download CSV
                </a>
            </div>

            <div className={"flex items-end justify-end mb-2 absolute -top-8 right-0 "}>
                {page > 1 && (
                    <Link to={`/found-jobs/${parseInt(page) - 1}`}>👈</Link>
                )}
                <span> {page}</span>
                <Link to={`/found-jobs/${parseInt(page) + 1}`}>👉</Link>
            </div>
            {matchedJobs && matchedJobs.map((job) => {
                return (
                    <div key={job.external_id}>
                        <SingleMatchedJob job={job}/>
                    </div>
                )
            })}
        </div>
    )

}

type props = {
    job: MatchedJob
}

const SingleMatchedJob = (props: props) => {
    const makeTime = (t: string) => {
        let time = new Date(t);
        // console.log("time is", time.getMonth() + time.getHours() + time.getMinutes());
        return time.toLocaleString()
    }
    const truncateString = (string: string, maxLength: number) =>
        string.length > maxLength
            ? `${string.substring(0, maxLength)}…`
            : string
    return (
        <div className={"flex flex-col bg-coolGray-100 my-6 rounded-xl"}>
            {props.job.lead.GoogleFailureMessage && props.job.lead.GoogleFailureMessage.length > 2 &&
            <p className={"text-sm px-8 pt-8 text-red-400"}>error: {props.job.lead.GoogleFailureMessage}</p>}
            <div
                className={` justify-around divide-x-4 divide-coolGray-200 px-8 pb-10 flex text-lg text-blueGray-800 `}
                key={props.job.lead.id}>
                <div className={"flex flex-1 flex-col space-y-2"}>
                    <p className={"text-2xl font-bold text-blueGray-600 mb-2"}>Lead Info</p>
                    {props.job.lead.start_time && <p className={"flex"}>
                        <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                             width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
                        </svg>
                        {makeTime(props.job.lead.start_time as string)}</p>}
                    {props.job.lead.customer_phone_number && <p className={"flex"}>
                        <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                             width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/>
                        </svg>
                        {props.job.lead.customer_phone_number}</p>}
                    {props.job.lead.customer_phone_number && <p className={"flex"}>
                        <svg className={"mr-2"} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                             width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z"/>
                        </svg>
                        {props.job.lead.customer_name}</p>}
                    {props.job.lead.source && <p>
                        {props.job.lead.source}
                    </p>}
                    {props.job.lead.recording_player &&
                    <a target={"_blank"} className={"underline"} href={props.job.lead.recording_player}>Recording
                        ({props.job.lead.recording_duration}s)</a>}
                </div>
                <div className={"flex-1 pl-4 flex flex-col justify-center"}>
                    {props.job.value && <p>Value: {props.job.value}</p>}
                    {props.job.submitted_value && <p>Submitted: {props.job.submitted_value}</p>}
                    {props.job.lead.campaign && <p>Campaign: {props.job.lead.campaign}</p>}
                    {props.job.external_id && <p>ID: {props.job.external_id}</p>}
                    {props.job.won_date && <p>Won Date: {makeTime(props.job.won_date.toString())}</p>}
                    {props.job.description && <p>Description {truncateString(props.job.description, 150)}</p>}
                </div>
            </div>
            <div className={""}>
                <div className={"h-0 -mt-8"}>
                    {props.job.lead.SuccessfullySubmittedGoogleAds &&
                    <p className={"text-center pb-2 text-green-600"}>Successfully submitted to Google Ads</p>}
                </div>
            </div>

        </div>
    )
}