type props = {
    children: any
}

export const Container = (props: props) => {
    return (
    <div className={"shadow-2xl bg-white flex flex-col rounded-2xl py-8 px-8"}>
        {props.children}
    </div>
    )

}