import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../applicationState";
import useSWR, {mutate} from "swr";
import {fetcher} from "../../../utils/fetch";
import {CompanyNotes} from "../../../types/models";
import {patchForm} from "../../../utils/postForm";

export const UseGetCompanyState = () => {
    const {leads, setLeads, currentCompany, setCompanyNotes, companyNotes} =  useContext(ApplicationContext);
    const [mounted, setMounted] = useState(false);
    const {data, error} = useSWR(mounted ? `${process.env.REACT_APP_API}/v1/api/public-company/${currentCompany.UUID}/company-notes` : null, fetcher);

    useEffect(() => {
        if(currentCompany && currentCompany.UUID){
            setMounted(true);
        }
    }, [currentCompany])

    useEffect(() => {
        console.log("this data is", data);
        if (!data || Object.keys(data).length === 0){
            console.log("clearing")
            setCompanyNotes({
                GoogleAdsPhoneReportLastUpdated: "",
                notes: "",
            })
            return
        }
        console.log("setting")
        let marshalled = data as CompanyNotes;
        setCompanyNotes({...marshalled})
    }, [data])

    const PatchCompanyNotes = async () => {
        if (!companyNotes){
            return
        }
        let response = await patchForm(`/v1/api/public-company/${currentCompany.UUID}/company-notes`, JSON.stringify(companyNotes));
        let readResponse = await response.responseMessage.json();
        if (!response.isOk){
            window.alert(readResponse.Message);
        }
        mutate(`${process.env.REACT_APP_API}/v1/api/public-company/${currentCompany.UUID}/company-notes`)
    }

    return {PatchCompanyNotes}
}

