import React, {useEffect, useState} from "react";
import {Company, CompanyNotes, Job, Lead, MatchedJob} from "../types/models";
import {useLocation} from "react-router-dom";

export const ApplicationContext = React.createContext({
    currentCompany: {} as Company,
    setCurrentCompany: (v: Company) => {},
    allCompanies: [] as Company[],
    setAllCompanies: (v: Company[]) => {},
    leads: [] as Lead[],
    setLeads: (v: Lead[]) => {},
    param: "",
    setParam: (v: string) => {},
    filter: "",
    setFilter: (v: string) => {},
    searchNumbers: "",
    setSearchNumbers: (v: string) => {},
    globalError: "",
    setGlobalError: (v: string) => {},
    setCompanyNotes: (v: CompanyNotes) => {},
    companyNotes: {
        notes: "",
        GoogleAdsPhoneReportLastUpdated: ""
    } as CompanyNotes,
    jobs: [] as Job[],
    setJobs: (v: Job[]) => {},
    matchedJobs: [] as MatchedJob[],
    setMatchedJobs: (v: MatchedJob[]) => {},}
)


export const ApplicationState = () => {
    const [currentCompany, setCurrentCompany] = useState({} as Company);
    const [allCompanies, setAllCompanies] = useState([] as Company[]);
    const [leads, setLeads] = useState([] as Lead[])
    const [param, setParam] = useState("")
    const [searchNumbers, setSearchNumbers] = useState("")
    const [globalError, setGlobalError] = useState("")
    const [filter, setFilter] = useState("")
    const [companyNotes, setCompanyNotes] = useState({
        notes: "",
        GoogleAdsPhoneReportLastUpdated: ""
    } as CompanyNotes)
    const [jobs, setJobs] = useState([] as Job[])
    const [matchedJobs, setMatchedJobs] = useState([] as MatchedJob[])

    const values = {
        currentCompany,
        setCurrentCompany,
        allCompanies,
        setAllCompanies,
        leads,
        setLeads,
        param, setParam,
        searchNumbers, setSearchNumbers,
        globalError, setGlobalError,
        companyNotes, setCompanyNotes,
        filter, setFilter,
        jobs, setJobs,
        matchedJobs, setMatchedJobs
    }
    return values;
}