import {ApplicationContext} from "../../applicationState";
import {useContext, useEffect, useState} from "react";
import useSWR from "swr";
import {fetcher} from "../../../utils/fetch";
import {UseDebounce} from "../../../utils/useDebounce";

type props = {
    page: string
}

export const NewLeadsData = (props: props) => {
    const [mounted, setMounted] = useState(false);
    const {leads, setLeads, currentCompany, searchNumbers, setGlobalError, filter} =  useContext(ApplicationContext);
    const [url, setURL] = useState("");
    const {data, error} = useSWR(mounted ? url : null, fetcher);
    const debouncedSearch = UseDebounce(searchNumbers, 200)

    useEffect(() => {
        console.log("This runs")
        if(!currentCompany || !currentCompany.UUID){
            console.log("Bug: Current company is undefined")
            setGlobalError("Company ID Undefined")
            return
        }
        setGlobalError("");
        if (searchNumbers !== ""){
            setURL(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/search?key=${debouncedSearch}`)
            return
        }
        console.log("setting filter to", filter);
        console.log("setting page to", props.page);
        setURL(`${process.env.REACT_APP_API}/v1/api/leads/${currentCompany.UUID}/new-leads/${props.page}?filter=${filter}`)
    }, [debouncedSearch,currentCompany, filter, props.page])


    useEffect(() => {
        console.log("data is", data);
    }, [data])


    useEffect(() => {
        if(currentCompany && currentCompany.UUID){
            setMounted(true);
        }
    }, [currentCompany])


    useEffect(() => {
        if (data){
            setLeads(data);
        }
    }, [data])
    return error;
}